// @flow
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  LEFT_SIDEBAR_TYPE_FIXED,
  LEFT_SIDEBAR_TYPE_CONDENSED,
  LEFT_SIDEBAR_TYPE_SCROLLABLE,
} from "../../constants/layout";

const LeftSideBarType = ({
  changeLeftSiderbarType,
  leftSideBarType,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="mt-4">{t("Left Sidebar")}</h5>
      <hr className="mt-1" />

      <Form.Check className="form-check form-switch mb-1 mt-3">
        <Form.Check.Input
          type="radio"
          name="leftsidebar-size"
          id="default-check"
          value={LEFT_SIDEBAR_TYPE_FIXED}
          onChange={(e) => changeLeftSiderbarType(e.target.value)}
          checked={leftSideBarType === LEFT_SIDEBAR_TYPE_FIXED}
        />
        <Form.Check.Label htmlFor="default-check">
          {t("Fixed")}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          name="leftsidebar-size"
          id="condensed-check"
          value={LEFT_SIDEBAR_TYPE_CONDENSED}
          onChange={(e) => changeLeftSiderbarType(e.target.value)}
          checked={leftSideBarType === LEFT_SIDEBAR_TYPE_CONDENSED}
        />
        <Form.Check.Label htmlFor="condensed-check">
          {t("Condensed")}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          name="leftsidebar-size"
          id="compact-check"
          value={LEFT_SIDEBAR_TYPE_SCROLLABLE}
          onChange={(e) => changeLeftSiderbarType(e.target.value)}
          checked={leftSideBarType === LEFT_SIDEBAR_TYPE_SCROLLABLE}
        />
        <Form.Check.Label htmlFor="compact-check">
          {t("Scrollable")}
        </Form.Check.Label>
      </Form.Check>
    </>
  );
};

export default LeftSideBarType;
