import React, { createContext, useCallback, useContext, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import useAxios from "axios-hooks";
import axios from "axios";

const DuaContext = createContext();
const baseUrl = "/duas";

// Fetchers
const fetchDua = async ({ duaType, id }) => {
  const { data } = await axios.get(`${baseUrl}/${duaType}/${id}`);
  return data;
};

export const DuaProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [duaParams, setDuaParams] = useState({ duaType: null, id: null });
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null)


  // Dua Lines Modal
  const [isDuaLinesOpen, setDuaLinesModal] = useState(false);
  const [isDLWizarOpen, setDLWizarModal] = useState(false);

  const toggleDLModal = useCallback(
    () => setDuaLinesModal(!isDuaLinesOpen),
    [isDuaLinesOpen]
  );
  const toggleDLWizarModal = useCallback(
    () => setDLWizarModal(!isDLWizarOpen),
    [isDLWizarOpen]
  );

  // Fetch DUA
  const {
    data: dua,
    isLoading: fetchLoading,
    error: fetchErorr,
  } = useQuery({
    queryKey: ["fetchDua", duaParams],
    queryFn: fetchDua.bind(null, duaParams),
    enabled: !!duaParams.duaType && !!duaParams.id, // Ensure query runs only when both params are set
  });

  const PendingDL = (fetchDL, invoiceId) => {
    return useQuery({
      queryKey: ["PendingDL"],
      queryFn: async () => {
        const { data } = await axios.get(
          `${baseUrl}/invoices/${invoiceId}/pending_dua_lines`
        );
        return data;
      },
      enabled: fetchDL && !!invoiceId,
      retry: false,
    });
  };



  // Create DUA
  const [{ loading: createLoading, error: createErorr }, executeCreate] =
    useAxios({ url: baseUrl, method: "POST" }, { manual: true });
  // Update DUA
  const [{ loading: updateLoading, error: updateErorr }, executeUpdate] =
    useAxios({ url: baseUrl, method: "PUT" }, { manual: true });

  // Functions
  const createDua = useCallback(
    async (data) => {
      try {
        const response = await executeCreate({ data });
        queryClient.invalidateQueries("fetchDua");
        return response.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    [executeCreate, queryClient]
  );

  const updateDua = useCallback(
    async (data) => {
      try {
        const response = await executeUpdate({ data });
        queryClient.invalidateQueries("fetchDua");
        return response.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    [executeUpdate, queryClient]
  );

  return (
    <DuaContext.Provider
      value={{
        dua,
        loading: fetchLoading || createLoading || updateLoading,
        error: fetchErorr || createErorr || updateErorr,
        createDua,
        updateDua,
        setDuaParams,
        isDuaLinesOpen,
        toggleDLModal,
        isDLWizarOpen,
        toggleDLWizarModal,
        PendingDL,
        selectedInvoiceId,
        setSelectedInvoiceId
      }}
    >
      {children}
    </DuaContext.Provider>
  );
};

export function useDuaContext() {
  const context = useContext(DuaContext);
  if (!context) {
    throw new Error("Something went wrong with DUA context");
  }
  return context;
}
