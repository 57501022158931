import { DuaProvider } from "context/DuaContext";
import React from "react";
import { Outlet } from "react-router";

const Duas = () => {
  return (
    <DuaProvider>
      <Outlet />
    </DuaProvider>
  );
};

export default Duas;
