export * from './array';
export * from './layout';
export * from './string';


/**
 * Formats a number as currency.
 * @param {number} value - The number to format.
 * @param {string} currencyCode - The ISO currency code.
 * @returns {string} The formatted currency string.
 */
const currencies = {
  USD: "en-US",
  CRC: "es-CR",
  EUR: "en-GB",
  JPY: "ja-JP",
  GBP: "en-GB",
  AUD: "en-AU",
  CAD: "en-CA",
};

export const formatCurrency = (value, currencyCode) => {
  return new Intl.NumberFormat(currencies[currencyCode], {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  }).format(value);
}

export const formattedDate = (value) => {
  return new Intl.DateTimeFormat('es-CR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(value)
};