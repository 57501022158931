import React from "react";
import classNames from "classnames";
import SelectInput from "components/FormInputs/SelectInput";
import TextInput from "components/FormInputs/TextInput";
import { isEmpty } from "lodash";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Schema } from "./schema";
import { deserialize, serialize } from "./serializer";
import { CenteredRow } from "../../../components/styles";
import { useCustomers } from "hooks/control-panel/useCustomers";
import CheckInput from "components/FormInputs/CheckInput";

const CustomerForm = ({ model = {}, options = {} }) => {
  const { t } = useTranslation();
  const { createCustomer } = useCustomers();

  // const isNew = isNil(model?.id);
  const navigate = useNavigate();

  const defaultValues = serialize(model, options);
  const methods = useForm({
    resolver: Schema(),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitted, errors },
  } = methods;

  // Events
  const onSubmit = async (formData) => {
    const payload = deserialize(formData);
    await createCustomer(payload);
    // const dataSaved = await createCustomer(payload);
    // const {
    //   data: { id },
    // } = dataSaved;
    navigate("/control-panel/customers/");
  };

  const onClose = () => navigate("/control-panel/customers");
  !isEmpty(errors) && console.log("errors", errors);

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        className={classNames("mx-auto", { "was-validated": isSubmitted })}
      >
        <CenteredRow>
          <Col xl={4}>
            <TextInput
              id="name"
              name="name"
              label={t("Name")}
              containerClass={"mb-3"}
              required={true}
            />
          </Col>
          <Col xl={4}>
            <TextInput
              id="email"
              name="email"
              label={t("Email")}
              containerClass={"mb-3"}
              required={true}
            />
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col xl={4}>
            <TextInput
              id="documentNumber"
              name="documentNumber"
              label={t("Identification")}
              containerClass={"mb-3"}
              required={true}
            />
          </Col>
          <Col xl={4}>
            <SelectInput
              id="personalDocumentType"
              name="personalDocumentType"
              label={t("Personal Document Type")}
              containerClass={"mb-3"}
              required={true}
              rows={6}
              options={options.personalDocumentTypes}
              placeholder={t("Select an option")}
            />
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col xl={4}>
            <TextInput
              id="phone"
              name="phone"
              label={t("Phone")}
              containerClass={"mb-3"}
              required={true}
            />
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col xl={8}>
            <hr className="border-bottom border-1 border-dark"></hr>
          </Col>
        </CenteredRow>
        <CenteredRow>
          <Col xl={8}>
            <h5>{t("Customer Type")}</h5>
          </Col>
        </CenteredRow>
        <CenteredRow>
          <CenteredRow>
            <Col xl={2}>
              <CheckInput
                id="declarant"
                name="declarant"
                label={t("Declarant")}
                containerClass={"mb-3"}
                required={false}
              />
            </Col>
            <Col xl={2}>
              <CheckInput
                id="customsAgent"
                name="customsAgent"
                label={t("Customs Agent")}
                containerClass={"mb-3"}
                required={false}
              />
            </Col>
            <Col xl={2}>
              <CheckInput
                id="carrier"
                name="carrier"
                label={t("Carrier")}
                containerClass={"mb-3"}
                required={false}
              />
            </Col>
          </CenteredRow>
          <CenteredRow>
            <Col xl={2}>
              <CheckInput
                id="driver"
                name="driver"
                label={t("Driver")}
                containerClass={"mb-3"}
                required={false}
              />
            </Col>
            <Col xl={2}>
              <CheckInput
                id="importer"
                name="importer"
                label={t("Importer")}
                containerClass={"mb-3"}
                required={false}
              />
            </Col>
            <Col xl={2}>
              <CheckInput
                id="exporter"
                name="exporter"
                label={t("Exporter")}
                containerClass={"mb-3"}
                required={false}
              />
            </Col>
          </CenteredRow>
          <CenteredRow>
            <Col xl={2}>
              <CheckInput
                id="consignee"
                name="consignee"
                label={t("Consignee")}
                containerClass={"mb-3"}
                required={false}
              />
            </Col>
            <Col xl={2}>
              <CheckInput
                id="freeZoneCompany"
                name="freeZoneCompany"
                label={t("Free Zone Company")}
                containerClass={"mb-3"}
                required={false}
              />
            </Col>
            <Col xl={2}>
              <CheckInput
                id="activeImprovementCompany"
                name="activeImprovementCompany"
                label={t("Active Improvement Company")}
                containerClass={"mb-3"}
                required={false}
              />
            </Col>
          </CenteredRow>
        </CenteredRow>
        <Row className="mt-2 text-center">
          <Col>
            <Button type="submit" variant="success" disabled={!isDirty}>
              {t("Create")}
            </Button>
            <Button variant="danger" className="mx-4" onClick={onClose}>
              {t("Cancel")}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CustomerForm;
