import { Navigate, useLocation } from "react-router-dom";
import { useUserSessionContext } from "../context/UserSessionContext";

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }) => {
  let location = useLocation();
  const { isUserAuthenticated } = useUserSessionContext();
  /**
   * not logged in so redirect to login page with the return url
   */
  if (!isUserAuthenticated()) {
    return (
      <Navigate to={"/account/login"} state={{ from: location }} replace />
    );
  }

  // check if route is restricted by role
  // if (roles && roles.indexOf(loggedInUser.role) === -1) {
  //     // role not authorised so redirect to home page
  //     return <Navigate to={{ pathname: '/' }} />;
  // }

  return <RouteComponent />;
};

export default PrivateRoute;
