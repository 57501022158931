// @flow
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  LEFT_SIDEBAR_THEME_DEFAULT,
  LEFT_SIDEBAR_THEME_LIGHT,
  LEFT_SIDEBAR_THEME_DARK,
} from "../../constants/layout";

const LeftSideBarTheme = ({
  changeLeftSidebarTheme,
  leftSideBarTheme,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="mt-4">{t("Left Sidebar Color")}</h5>
      <hr className="mt-1" />

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          name="theme"
          id="brand-check"
          value={LEFT_SIDEBAR_THEME_DEFAULT}
          onChange={(e) => changeLeftSidebarTheme(e.target.value)}
          checked={leftSideBarTheme === LEFT_SIDEBAR_THEME_DEFAULT}
        />
        <Form.Check.Label htmlFor="brand-check">
          {t("Default")}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          name="theme"
          id="light-check"
          value={LEFT_SIDEBAR_THEME_LIGHT}
          onChange={(e) => changeLeftSidebarTheme(e.target.value)}
          checked={leftSideBarTheme === LEFT_SIDEBAR_THEME_LIGHT}
        />
        <Form.Check.Label htmlFor="light-check">{t("Light")}</Form.Check.Label>
      </Form.Check>

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          name="theme"
          id="dark-check"
          value={LEFT_SIDEBAR_THEME_DARK}
          onChange={(e) => changeLeftSidebarTheme(e.target.value)}
          checked={leftSideBarTheme === LEFT_SIDEBAR_THEME_DARK}
        />
        <Form.Check.Label htmlFor="dark-check">{t("Dark")}</Form.Check.Label>
      </Form.Check>
    </>
  );
};

export default LeftSideBarTheme;
