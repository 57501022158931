import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const TextInput = ({
  label,
  name,
  required,
  labelClassName,
  containerClass,
  className,
  placeholder,
  rows = 3,
  autoComplete,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group className={containerClass}>
      {label ? (
        <Form.Label className={labelClassName} htmlFor={name}>
          {label}
          {required && <span title="required">&nbsp;*</span>}
        </Form.Label>
      ) : null}

      <InputGroup className="mb-0 z-index-zero">
        <Form.Control
          id={name}
          type="text"
          className={className}
          placeholder={placeholder}
          isInvalid={Boolean(errors[name])}
          rows={rows}
          autoComplete={autoComplete}
          required={required}
          {...register(name)}
        />
      </InputGroup>

      {errors && errors[name] ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors[name]["message"]}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default TextInput;
