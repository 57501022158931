// @flow
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// constants
import { LAYOUT_COLOR_LIGHT, LAYOUT_COLOR_DARK } from "../../constants/layout";

const LayoutColor = ({ changeLayoutColorScheme, layoutColor }) => {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="mt-4">{t("Color Scheme")}</h5>

      <hr className="mt-1" />

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          onChange={(e) => changeLayoutColorScheme(e.target.value)}
          name="layout-color"
          value={LAYOUT_COLOR_LIGHT}
          id="light-mode"
          checked={layoutColor === LAYOUT_COLOR_LIGHT}
        />
        <Form.Check.Label htmlFor="vertical-layout">
          {t("Light Mode")}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          onChange={(e) => changeLayoutColorScheme(e.target.value)}
          name="layout-color"
          value={LAYOUT_COLOR_DARK}
          id="dark-mode"
          checked={layoutColor === LAYOUT_COLOR_DARK}
        />
        <Form.Check.Label htmlFor="horizontal-layout">
          {t("Dark Mode")}
        </Form.Check.Label>
      </Form.Check>
    </>
  );
};

export default LayoutColor;
