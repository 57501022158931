export const serialize = (data, options) => {
  return data;
};

export const deserialize = (formData) => {
  const {
    description,
    name,
    email,
    phone,
    documentNumber,
    personalDocumentType,
    declarant,
    customsAgent,
    carrier,
    driver,
    importer,
    consignee,
    exporter,
    freeZoneCompany,
    activeImprovementCompany,
  } = formData;

  return {
    data: {
      description,
      name,
      email,
      phone,
      documentNumber,
      personalDocumentTypeId: personalDocumentType.value,
      declarant,
      customsAgent,
      carrier,
      driver,
      importer,
      consignee,
      exporter,
      freeZoneCompany,
      activeImprovementCompany,
    },
  };
};
