import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right">
              <form className="d-flex">
                <div className="input-group"></div>
                <Link to="#" className="btn btn-primary ms-2">
                  <i className="mdi mdi-autorenew"></i>
                </Link>
                <Link to="#" className="btn btn-primary ms-1">
                  <i className="mdi mdi-filter-variant"></i>
                </Link>
              </form>
            </div>
            <h4 className="page-title">Dashboard</h4>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={5} lg={6}></Col>

        <Col xl={7} lg={6}></Col>
      </Row>

      <Row>
        <Col lg={8}></Col>
        <Col lg={4}></Col>
      </Row>

      <Row>
        <Col xl={{ span: 6, order: 1 }} lg={{ span: 12, order: 2 }}></Col>
        <Col xl={3} lg={{ span: 6, order: 1 }}></Col>
        <Col xl={3} lg={{ span: 6, order: 1 }}></Col>
      </Row>
    </>
  );
};

export default Dashboard;
