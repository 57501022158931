// @flow
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  LAYOUT_VERTICAL,
  LAYOUT_HORIZONTAL,
  LAYOUT_DETACHED,
  LAYOUT_FULL,
} from "../../constants/layout";

const LayoutTypes = ({ changeLayoutType, layoutType }) => {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="mt-3">{t("Layout")}</h5>

      <hr className="mt-1" />

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          onChange={(e) => changeLayoutType(e.target.value)}
          name="layout-type"
          value={LAYOUT_VERTICAL}
          id="vertical-layout"
          checked={layoutType === LAYOUT_VERTICAL}
        />
        <Form.Check.Label htmlFor="vertical-layout">
          {t("Vertical Layout")}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          onChange={(e) => changeLayoutType(e.target.value)}
          name="layout-type"
          value={LAYOUT_HORIZONTAL}
          id="horizontal-layout"
          checked={layoutType === LAYOUT_HORIZONTAL}
        />
        <Form.Check.Label htmlFor="horizontal-layout">
          {t("Horizontal Layout")}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          onChange={(e) => changeLayoutType(e.target.value)}
          name="layout-type"
          value={LAYOUT_DETACHED}
          id="detached-layout"
          checked={layoutType === LAYOUT_DETACHED}
        />
        <Form.Check.Label htmlFor="detached-layout">
          {t("Detached Layout")}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          onChange={(e) => changeLayoutType(e.target.value)}
          name="layout-type"
          value={LAYOUT_FULL}
          id="full-mode-layout"
          checked={layoutType === LAYOUT_FULL}
        />
        <Form.Check.Label htmlFor="full-mode-layout">
          {t("Full Layout")}
        </Form.Check.Label>
      </Form.Check>
    </>
  );
};

export default LayoutTypes;
