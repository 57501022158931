import React, { useContext, createContext, useMemo, useState } from "react";

const ApplicationContext = createContext();

export function ApplicationProvider(props) {
  const [appLoading, setAppLoading] = useState(false);

  const value = useMemo(
    () => ({
      appLoading,
      setAppLoading,
    }),
    [appLoading, setAppLoading]
  );
  return (
    <ApplicationContext.Provider
      value={value}
      {...props}
    ></ApplicationContext.Provider>
  );
}

export function useApplicationContext() {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error("Something went wrong with application context");
  }
  return context;
}
