import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export const Schema = () => {
  const { t } = useTranslation();

  return yupResolver(
    yup.object({
      description: yup
        .string()
        .trim()
        .min(10, t("Min 10 characters"))
        .max(255, t("Max 255 characters")),
      name: yup.string().required(t("Name is required")),
      email: yup.string().required(t("Email is required")),
      phone: yup.string().required(t("Phone is required")),
      documentNumber: yup.string().required(t("Identification is required")),
      personalDocumentType: yup
        .object()
        .required(t("Document Type is required")),
      declarant: yup.boolean(),
      customsAgent: yup.boolean(),
      carrier: yup.boolean(),
      driver: yup.boolean(),
      importer: yup.boolean(),
      consignee: yup.boolean(),
      exporter: yup.boolean(),
      freeZoneCompany: yup.boolean(),
      activeImprovementCompany: yup.boolean(),
    })
  );
};
