import React from "react";
import axios from "axios";
import Qs from "qs";

import { configure } from "axios-hooks";
// For Saas import Saas.scss
import "./assets/scss/Saas.scss";
import { LRUCache } from "lru-cache";
import config from "./config";

import Routes from "./routes/Routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ApplicationProvider } from "context/ApplicationContext";

const { API_URL, TOKEN_KEY } = config;

if (process.env.NODE_ENV === "development") {
  console.log("config", config);
  console.log("process.env", process.env);
}
// Set up axios
axios.defaults.baseURL = API_URL;

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
window.axios = axios;
const cache = new LRUCache({ max: 10 });
configure({ axios, cache });

axios.interceptors.request.use((config) => {
  let token = null;
  try {
    token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      config.headers = {
        authorization: `${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }
  } catch (e) {}

  //
  config.paramsSerializer = (params) => {
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,

      // encoder: (str, defaultEncoder, charset, type)=>{
      //   if (type === 'key') return str;
      //   if (type === 'value') return defaultEncoder(str);
      // }
    });
  };
  return config;
});
// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === "ERR_CANCELED") {
      // aborted in useEffect cleanup
      return Promise.resolve({ status: 499 });
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    // } else if (error && error.response && error.response.status === 403) {
    //   window.location.href = "/access-denied";
    } else {
      switch (error?.response?.status) {
        case 401:
          console.log('error.response.data', error)
          message = error.response.data["error"];
          
          localStorage.removeItem(TOKEN_KEY);
          if (error.config.url !== '/login')
            window.location.href = "/account/login";
          break;
        case 403:
          message = "Access Forbidden";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        case 422:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default: {
          message =
            error.response && error.response.data
              ? error.response.data["message"]
              : error.message || error;
        }
      }

      return Promise.reject({status: error?.response?.status, message});
    }
  }
);
const queryClient = new QueryClient();

function App() {
  return (
    <ApplicationProvider>
      <QueryClientProvider client={queryClient}>
        <Routes></Routes>
      </QueryClientProvider>
    </ApplicationProvider>
  );
}

export default App;
