import classNames from "classnames";
import React from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";

const SelectInput = ({
  id,
  label,
  name,
  required,
  labelClassName,
  containerClass,
  className,
  placeholder,
  rows = 3,
  autoComplete,
  options = [],
  tooltip,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group className={containerClass}>
      {label ? (
        <Form.Label className={labelClassName} htmlFor={name}>
          {label}
          {required && <span title="required">&nbsp;*</span>}
          {tooltip && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-icon">{tooltip}</Tooltip>}
            >
              <i className="mdi mdi-information-outline"></i>
            </OverlayTrigger>
          )}
        </Form.Label>
      ) : null}

      <InputGroup className={classNames("mb-0", className)}>
        <Controller
          id={id}
          name={name}
          control={control}
          isInvalid={Boolean(errors[name])}
          required={required}
          rows={rows}
          autoComplete={autoComplete}
          // defaultValue={null} // Initial value should be null or whatever is appropriate

          render={({ field }) => (
            <Select
              id={id}
              {...field}
              options={options}
              inputId={name}
              placeholder={placeholder}
              className={classNames("w-100")}
              classNames={{
                control: (state) => (Boolean(errors[name]) ? "is-invalid" : ""),
              }}
              {...rest}
            />
          )}
        />
      </InputGroup>

      {errors && errors[name] ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors[name]["message"]}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default SelectInput;
