// @flow
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { LAYOUT_WIDTH_FLUID, LAYOUT_WIDTH_BOXED } from "../../constants/layout";

const LayoutWidth = ({ changeWidthMode, layoutWidth, layoutConstants }) => {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="mt-4">{t("Width")}</h5>

      <hr className="mt-1" />

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          name="width"
          id="fluid-check"
          value={LAYOUT_WIDTH_FLUID}
          onChange={(e) => changeWidthMode(e.target.value)}
          checked={layoutWidth === LAYOUT_WIDTH_FLUID}
        />
        <Form.Check.Label htmlFor="fluid-check">{t("Fluid")}</Form.Check.Label>
      </Form.Check>

      <Form.Check className="form-check form-switch mb-1">
        <Form.Check.Input
          type="radio"
          name="width"
          id="boxed-check"
          value={LAYOUT_WIDTH_BOXED}
          onChange={(e) => changeWidthMode(e.target.value)}
          checked={layoutWidth === LAYOUT_WIDTH_BOXED}
        />
        <Form.Check.Label htmlFor="boxed-check">{t("Boxed")}</Form.Check.Label>
      </Form.Check>
    </>
  );
};

export default LayoutWidth;
