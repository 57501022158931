import { useMemo } from "react";
import useAxios from "axios-hooks";

export const useCustomers = () => {
  const baseUrl = "/control_panel/customers/";
  
  // Create Import request
  const [
    {
      data: dataCreateCustomer,
      loading: loadingCreateCustomer,
      error: errorCreateCustomer,
    },
    createCustomer,
  ] = useAxios({ url: baseUrl, method: "POST" }, { manual: true });

  console.log("Respuesta de useAxios:", dataCreateCustomer);

  return useMemo(
    () => ({
      createCustomer,
      dataCreateCustomer,
      loadingCreateCustomer,
      errorCreateCustomer,
    }),
    [
      createCustomer,
      dataCreateCustomer,
      loadingCreateCustomer,
      errorCreateCustomer,
    ]
  );
};
