import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const CheckInput = ({
  label,
  name,
  required = false,
  containerClass,
  className,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group className={containerClass}>
      <Form.Check
        type="checkbox"
        id={name}
        label={label}
        className={className}
        required={required}
        {...register(name)}
      />

      {errors && errors[name] ? (
        <Form.Control.Feedback type="invalid" className="d-block">
          {errors[name]["message"]}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
};

export default CheckInput;
