import axios from "axios";
import PageTitle from "components/PageTitle";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router";
import CustomerForm from "forms/control-panel/CustomerForm";

const New = () => {
  const { t } = useTranslation();
  const options = useLoaderData();

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Control Panel"), path: "/control-panel/home" },
          { label: t("Customers"), path: "/control-panel/customers" },
          {
            label: t("New Customer"),
            path: "/control-panel/customers/new",
            active: true,
          },
        ]}
        title={t("New Customer")}
      />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <CustomerForm model={{}} options={options}/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default New;

export const customerDataLoaderOptions = async () => {
    const res = await axios.get('/options/control_panel/customer');
    const jsonResult = await res.data;
  
    return jsonResult;
  };